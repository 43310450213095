import { Injectable } from "@angular/core";
import { findLast } from "ramda";

import { isNullOrUndefined } from "@ops/shared";
import { AtSeaBunkerConsumptionMode, AtSeaVesselStatus, CargoBerthActivityType, Enumeration } from "@ops/shared/reference-data";

import { Command, CommandHandler } from "../../../mediator";
import { VoyageVesselStatusCalculator } from "../../../services/voyage-vessel-status-calculator";
import {
    atSeaBunkerConsumption,
    AtSeaBunkerConsumption,
    BunkerConsumed,
    BunkerRemainingOnboard,
    CpSpeedAndConsumption,
    Delivery,
    Destination,
    Voyage
} from "../../../shared/models";
import { createAtSeaBunkerConsumptionId } from "../../../shared/models/dtos/at-sea-bunker-consumption";
import { destinationHasActivityTypes, DestinationId } from "../../../state/model";

export class InitBunkerCommand extends Command {
    constructor(readonly cpSpeedAndConsumption: CpSpeedAndConsumption, readonly delivery: Delivery, readonly atSeaOnly: boolean, readonly isSimpleMode?: boolean) {
        super(InitBunkerCommandHandler, {});
    }
}

@Injectable({
    providedIn: "root"
})
export class InitBunkerCommandHandler implements CommandHandler {
    constructor(private voyageCargoStatusCalculator: VoyageVesselStatusCalculator) {}

    handle(voyage: Voyage, command: InitBunkerCommand) {
        if (!command.atSeaOnly) {
            const deliveryBunkers =
                command.delivery?.bunkers
                    ?.filter((b) => b.bunkerType)
                    .map(
                        (b) =>
                            <BunkerRemainingOnboard>{
                                type: b.bunkerType,
                                quantityAtSailing: null,
                                quantityAtArrival: b.quantity,
                                pricePerMt: b.price,
                                isBunkered: null
                            }
                    ) ?? [];
            const cpBunkers =
                command.cpSpeedAndConsumption?.cpBunkersConsumption
                    ?.filter((b) => b.type)
                    .map(
                        (b) =>
                            <BunkerRemainingOnboard>{
                                type: b.type,
                                quantityAtSailing: null,
                                quantityAtArrival: null,
                                pricePerMt: null,
                                isBunkered: null
                            }
                    ) ?? [];

            this.initFirstBunker(voyage.destinations, deliveryBunkers, cpBunkers);
            this.initSubsequentBunkers(voyage.destinations, cpBunkers);
        }

        const isModeChange = !isNullOrUndefined(command.isSimpleMode) && command.isSimpleMode !== (voyage.atSeaBunkerConsumptionMode?.id === AtSeaBunkerConsumptionMode.Simple.id);
        const isSimpleMode = command.isSimpleMode ?? voyage.atSeaBunkerConsumptionMode?.id === AtSeaBunkerConsumptionMode.Simple.id;
        if (isSimpleMode) {
            this.initSimpleAtSeaConsumption(voyage, isModeChange);
        } else {
            this.initAtSeaConsumption(voyage, isModeChange);
        }
    }

    private initFirstBunker(destinations: Destination[], deliveryBunkers: BunkerRemainingOnboard[], cpBunkers: BunkerRemainingOnboard[]) {
        if (
            destinations.length !== 0 &&
            destinations[0].berths &&
            destinations[0].berths.length !== 0 &&
            destinations[0].berths[0].cargoBerthActivities.length !== 0 &&
            destinations[0].berths[0].cargoBerthActivities[0].bunkersRemainingOnboard === null
        ) {
            destinations[0].berths[0].cargoBerthActivities[0].bunkersRemainingOnboard = deliveryBunkers.length !== 0 ? deliveryBunkers : cpBunkers;
        }
    }

    private initSubsequentBunkers(destinations: Destination[], cpBunkers: BunkerRemainingOnboard[]) {
        if (destinations.length === 0) {
            return;
        }
        let lastBunkers: BunkerRemainingOnboard[] = [];

        for (const destination of destinations) {
            const berths = destination.berths;
            if (!berths || berths.length === 0) {
                continue;
            }
            for (const berth of berths) {
                const activities = berth.cargoBerthActivities;
                if (activities.length === 0) {
                    continue;
                }

                for (const activity of activities) {
                    if (isNullOrUndefined(activity.bunkersRemainingOnboard)) {
                        activity.bunkersRemainingOnboard = lastBunkers.length !== 0 ? lastBunkers : cpBunkers;
                    } else if (activity.bunkersRemainingOnboard.length > 0) {
                        lastBunkers = [];
                        activity.bunkersRemainingOnboard.map((b) => {
                            lastBunkers.push({
                                type: b.type,
                                quantityAtSailing: null,
                                quantityAtArrival: null,
                                pricePerMt: null,
                                isBunkered: null
                            });
                        });
                    }
                }
            }
        }
    }

    private initAtSeaConsumption(voyage: Voyage, isModeChange: boolean) {
        voyage.atSeaBunkerConsumptionMode = AtSeaBunkerConsumptionMode.Default;

        if (isModeChange) {
            voyage.atSeaBunkersConsumption = [];
        } else {
            // Remove any bunkers consumption where the destination has been removed
            voyage.atSeaBunkersConsumption = (voyage.atSeaBunkersConsumption || []).filter((x) => {
                const destinationFrom = voyage.destinations.find((d) => d.id === x.destinationFromId);
                const destinationTo = voyage.destinations.find((d) => d.id === x.destinationToId);
                return destinationFrom && destinationTo;
            });
        }

        const destinations = voyage.destinations;
        if (!destinations || destinations.length <= 1) {
            return;
        }

        this.syncAtSeaBunkersConsumptionDestinations(voyage.atSeaBunkersConsumption, destinations);

        for (let i = 0; i < destinations.length - 1; i++) {
            const destinationFrom = destinations[i];
            const destinationTo = destinations[i + 1];

            const precedingBunkerRemainingOnBoard = this.getPrecedingBunkerRemainingOnBoard(destinationFrom);
            const followingBunkerRemainingOnBoard = this.getFollowingBunkerRemainingOnBoard(destinationTo);
            const uniqueBunkerTypes = this.getUniqueBunkerTypes(precedingBunkerRemainingOnBoard, followingBunkerRemainingOnBoard);
            const atSea = voyage.atSeaBunkersConsumption.find((x) => x.destinationFromId === destinationFrom.id && x.destinationToId === destinationTo.id);

            const bunkersConsumed = uniqueBunkerTypes.map((bunkerType) => {
                const quantityAtSailingSum = this.calculateQuantitySum(precedingBunkerRemainingOnBoard, bunkerType.id, (x) => x.quantityAtSailing);
                const quantityAtArrivalSum = this.calculateQuantitySum(followingBunkerRemainingOnBoard, bunkerType.id, (x) => x.quantityAtArrival);

                // User Story 23891: Speed and Cons - when user enters nothing (as opposed to zero)
                let quantityMt = null;
                if (quantityAtSailingSum !== null && quantityAtArrivalSum !== null) {
                    quantityMt = quantityAtSailingSum - quantityAtArrivalSum;
                }

                const bunkerConsumed = ((atSea && atSea.bunkersConsumed) || []).find((x) => x.type.id === bunkerType.id);
                if (bunkerConsumed) {
                    bunkerConsumed.quantityMt = quantityMt;
                    return bunkerConsumed;
                } else {
                    return <BunkerConsumed>{
                        pricePerMt: null,
                        quantityMt,
                        type: bunkerType
                    };
                }
            });

            if (atSea) {
                atSea.bunkersConsumed = bunkersConsumed;
            } else {
                const atSeaBunkerConsumptionId = createAtSeaBunkerConsumptionId();

                const atSeaBunkersConsumption = <AtSeaBunkerConsumption>{
                    id: atSeaBunkerConsumptionId,
                    destinationFromId: destinationFrom.id,
                    destinationToId: destinationTo.id,
                    bunkersConsumed,
                    comments: null,
                    daysAboveForce: null,
                    distance: null,
                    cargoStatus: null
                };

                voyage.atSeaBunkersConsumption = [...voyage.atSeaBunkersConsumption, atSeaBunkersConsumption];
            }
        }

        this.voyageCargoStatusCalculator.update(voyage.destinations, voyage.atSeaBunkersConsumption);
    }

    private initSimpleAtSeaConsumption(voyage: Voyage, isModeChange: boolean) {
        voyage.atSeaBunkerConsumptionMode = AtSeaBunkerConsumptionMode.Simple;

        const ballastDestinationIndex = voyage.destinations.findIndex((d) => destinationHasActivityTypes(d, CargoBerthActivityType.BallastLeg));
        const firstLoadDestination = voyage.destinations.find((d) => destinationHasActivityTypes(d, CargoBerthActivityType.Load));
        const lastDischargeDestination = findLast<Destination>((d) => destinationHasActivityTypes(d, CargoBerthActivityType.Discharge))(voyage.destinations);

        const atSeaBunkerConsumptions = isModeChange ? new Array<AtSeaBunkerConsumption>() : voyage.atSeaBunkersConsumption;

        const upsert = (vesselStatus: AtSeaVesselStatus, destinationFromId: DestinationId, destinationToId: DestinationId, index?: number) => {
            const existing = atSeaBunkerConsumptions.find((c) => c.vesselStatus?.id === vesselStatus.id);

            if (existing) {
                existing.destinationFromId = destinationFromId;
                existing.destinationToId = destinationToId;
            } else {
                const newBunkerConsumption = atSeaBunkerConsumption(createAtSeaBunkerConsumptionId(), destinationFromId, destinationToId, vesselStatus);

                if (!isNullOrUndefined(index)) {
                    atSeaBunkerConsumptions.splice(index, 0, newBunkerConsumption);
                } else {
                    atSeaBunkerConsumptions.push(atSeaBunkerConsumption(createAtSeaBunkerConsumptionId(), destinationFromId, destinationToId, vesselStatus));
                }
            }
        };
        const remove = (vesselStatus: AtSeaVesselStatus) => {
            const index = atSeaBunkerConsumptions.findIndex((c) => c.vesselStatus?.id === vesselStatus.id);
            if (index >= 0) {
                atSeaBunkerConsumptions.splice(index, 1);
            }
        };

        // Assuming only one ballast here - before first load (it's simple mode)
        if (ballastDestinationIndex >= 0) {
            const ballastDestination = voyage.destinations[ballastDestinationIndex];
            const firstLoadAfterBallast = voyage.destinations.find((d, i) => i > ballastDestinationIndex && destinationHasActivityTypes(d, CargoBerthActivityType.Load));

            if (firstLoadAfterBallast) {
                // Always insert at the start
                upsert(AtSeaVesselStatus.Ballast, ballastDestination.id, firstLoadAfterBallast.id, 0);
            } else {
                remove(AtSeaVesselStatus.Ballast);
            }
        } else {
            remove(AtSeaVesselStatus.Ballast);
        }

        if (firstLoadDestination && lastDischargeDestination) {
            upsert(AtSeaVesselStatus.Laden, firstLoadDestination.id, lastDischargeDestination.id);
        } else {
            remove(AtSeaVesselStatus.Laden);
        }

        voyage.atSeaBunkersConsumption = atSeaBunkerConsumptions;

        if (isModeChange) {
            voyage.destinations.forEach((d) => d.berths.forEach((b) => b.cargoBerthActivities.forEach((c) => (c.bunkersRemainingOnboard = null))));
        }
    }

    private getUniqueBunkerTypes(precedingBunkerRemainingOnBoard: BunkerRemainingOnboard[], followingBunkerRemainingOnBoard: BunkerRemainingOnboard[]) {
        const precedingBunkerTypes = precedingBunkerRemainingOnBoard.map((x) => x.type);
        const followingBunkerTypes = followingBunkerRemainingOnBoard.map((x) => x.type);
        return this.removeDuplicateBunkerTypes(precedingBunkerTypes.concat(followingBunkerTypes));
    }

    private calculateQuantitySum(bunkersRemaining: BunkerRemainingOnboard[], bunkerTypeId: number, getSumField: (t: BunkerRemainingOnboard) => number) {
        const arr: number[] = bunkersRemaining.filter((x) => x.type.id === bunkerTypeId && getSumField(x) !== null && getSumField(x) !== undefined).map((x) => getSumField(x));

        // User Story 23891: Speed and Cons - when user enters nothing (as opposed to zero)
        if (arr.length === 0) {
            return null;
        }

        return arr.reduce((acc, curr) => acc + curr, 0);
    }

    private removeDuplicateBunkerTypes(bunkerTypes: Enumeration[]) {
        return bunkerTypes.filter((obj, pos, arr) => arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos);
    }

    private getPrecedingBunkerRemainingOnBoard(destination: Destination): BunkerRemainingOnboard[] {
        return (
            (destination &&
                destination.berths &&
                destination.berths.length &&
                destination.berths[destination.berths.length - 1].cargoBerthActivities &&
                destination.berths[destination.berths.length - 1].cargoBerthActivities.length &&
                destination.berths[destination.berths.length - 1].cargoBerthActivities[destination.berths[destination.berths.length - 1].cargoBerthActivities.length - 1]
                    .bunkersRemainingOnboard) ||
            []
        ).filter((x) => !!x.type);
    }

    private getFollowingBunkerRemainingOnBoard(destination: Destination): BunkerRemainingOnboard[] {
        return (
            (destination &&
                destination.berths &&
                destination.berths.length &&
                destination.berths[0].cargoBerthActivities &&
                destination.berths[0].cargoBerthActivities.length &&
                destination.berths[0].cargoBerthActivities[0].bunkersRemainingOnboard) ||
            []
        ).filter((x) => !!x.type);
    }

    private syncAtSeaBunkersConsumptionDestinations(atSeaBunkersConsumption: AtSeaBunkerConsumption[], destinations: Destination[]): void {
        if (atSeaBunkersConsumption.length && destinations.length) {
            for (let i = 0; i < atSeaBunkersConsumption.length; i++) {
                atSeaBunkersConsumption[i].destinationFromId = destinations[i].id;
                atSeaBunkersConsumption[i].destinationToId = destinations[i + 1].id;
            }
        }
    }
}
